import React from 'react';
import PropTypes from 'prop-types';
import TUTDWrapper from './TUTDWrapper';
import TUTDComponent from './TUTDComponent';
import FooterComponent from './FooterComponent';

const NotificationFeedbackComponent = (props) => {
    return (
        <>
            <TUTDWrapper component={ TUTDComponent }/>
            <FooterComponent/>
        </>
    );
};

NotificationFeedbackComponent.propTypes = {
    onChange: PropTypes.func
};
export default NotificationFeedbackComponent;
