import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';
import { InPageAlert, Button } from '@snsw-gel/react';
import { Field } from '@snsw-gel/react';

export const PageTitle = styled.div`
    max-width: 1224px;
    margin: 0px auto;
    width: 100%;
    padding-left: 30px
`;
export const ErrorAlert = styled(InPageAlert)`
    margin-top: 0;

    @media only screen and (min-width: ${screenSize.tablet}) {
        margin-right: 56px;    
        margin-bottom: 32px;
        width: 570px;
    }

    @media only screen and (max-width: ${screenSize.tablet}) {
        width: 100%;
        margin-bottom: 16px;
    }
`;

export const CustomerField = styled(Field)`

    @media only screen and (min-width: ${screenSize.tablet}) {
        max-width: 654px;
    }
    > span[class*="field-error"] {
        margin-top: 2rem;
    }
    
`;

export const FeedbackDiv = styled.div`
    > div[class*="welcome"] {
        margin-top: 32px;
        margin-bottom: 8px;
        color: rgb(36, 41, 52);
        font-size: 24px;
        font-weight: 800
    }
    > div[class*="improve"] {
        color: rgb(36, 41, 52);
        font-size: 16px;
        font-weight: 500
    }
`;

export const RatingContainer = styled.div`
    display: flex;
    margin:  20px 0;
    align-items: center;
    > div {
        margin-right:12px;
    }
    >span {
        color: rgb(100, 105, 116)
    }
`;

export const SVG = styled.svg`
    & .pressed >rect {
        fill: #002E52;
    }
    & .pressed >path {
        fill: white;
    }

`;

export const CharactersCounter = styled.span`
    float: right;
    font-size: 12px;
    color: #646974;
    margin-top: 5px
`;

export const SubmitButton = styled(Button)`
    margin-top: 32px;
`;