import React from 'react';
import {Col, Row, ContentContainer, Heading, SectionPage} from '@snsw-gel/react';
import PropTypes from 'prop-types';

const SuccessComponent = (props) => {
    return (
        <ContentContainer>
            <SectionPage>
                <Row justifyContent={ 'center' }>
                    <Col span={ 12 }>
                        <>
                            <Heading data-testid='portal-info-heading' level={ 1 } style={ { marginTop: '-12px', marginBottom: '28px'} }>
                                Thank you for your feedback!
                            </Heading>
                        </>
                    </Col>
                </Row>
            </SectionPage>
        </ContentContainer>
    );
};

SuccessComponent.propTypes = {
    onChange: PropTypes.func
};
export default SuccessComponent;
