import PropTypes from 'prop-types';
import {Footer, FooterContainer, Link} from './FooterComponent.styled';
const FooterComponent = (props) => {
    return (
        <FooterContainer>
            <Footer>
                If you need a response, <Link href='https://www.service.nsw.gov.au/contact-us' target='_blank' rel='noreferrer'>send an
                enquiry</Link> instead.
            </Footer>
        </FooterContainer>
    );
};

FooterComponent.propTypes = {
    onChange: PropTypes.func
};
export default FooterComponent;
