import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { GlobalStyle, gel3Themes } from '@snsw-gel/react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import GlobalNavWrapper from './Components/GlobalNavWrapper/GlobalNavWrapper';
import SuccessComponent from './Components/SuccessComponent/SuccessComponent';
import TranslationFeedbackComponent from './Components/TranslationFeedbackComponent/TranslationFeedbackComponent';
import NotificationFeedbackComponent from './Components/NotificationFeedbackComponent/NotificationFeedbackComponent';
import ThankYouComponent from './Components/ThankYouComponent/ThankYouComponent';

ReactDOM.render(
    <Router>
        <GlobalStyle themes={ gel3Themes }>
            <Routes>
                <Route exact path='/feedback' element={
                    // Login rout is unauthenticated and hence we do not wrap it with ProtectedComponent
                    <>
                        <GlobalNavWrapper component={ TranslationFeedbackComponent }/>
                    </>
                }/>
                <Route exact path='/translation-feedback' element={
                    // Login rout is unauthenticated and hence we do not wrap it with ProtectedComponent
                    <>
                        <GlobalNavWrapper component={ TranslationFeedbackComponent }/>
                    </>
                }/>
                <Route exact path='/notification-feedback' element={
                    <>
                        <GlobalNavWrapper component={ NotificationFeedbackComponent }/>
                    </>
                }/>
                <Route exact path='/thankyou' element={
                    <>
                        <GlobalNavWrapper component={ ThankYouComponent }/>
                    </>
                }/>
                <Route exact path='/success' element={
                    <>
                        <GlobalNavWrapper component={ SuccessComponent }/>
                    </>
                }/>
            </Routes>
        </GlobalStyle>,
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
