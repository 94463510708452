import styled from 'styled-components';

export const FooterContainer = styled.div`
    min-height: 64px;
    vertical-align:middle;
    display:table-cell;
    width: 100vw;
    padding: 15px 0;
    background: rgb(244, 247, 249);

`;

export const Footer= styled.div`
    padding-inline: 30px;
    margin-inline: auto;
    max-inline-size: 1224px;
    
`;

export const Link = styled.a`
    text-decoration: underline;

`;
