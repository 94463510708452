import React from 'react';
import PropTypes from 'prop-types';
import TUTDWrapper from '../NotificationFeedbackComponent/TUTDWrapper';

const ThankYouComponent = (props) => {
    return (
        <TUTDWrapper component={ SuccessInfo }/>
    );
};

const SuccessInfo = (props) => {
    return (
        <div className='improve'>
            We've received your feedback and we'll take it from here.
        </div>
    );
};
ThankYouComponent.propTypes = {
    onChange: PropTypes.func
};
export default ThankYouComponent;
