import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';
import { InPageAlert } from '@snsw-gel/react';
import { Field } from '@snsw-gel/react';

export const ErrorAlert = styled(InPageAlert)`
    margin-top: 0;

    @media only screen and (min-width: ${screenSize.tablet}) {
        margin-right: 56px;    
        margin-bottom: 32px;
        width: 570px;
    }

    @media only screen and (max-width: ${screenSize.tablet}) {
        width: 100%;
        margin-bottom: 16px;
    }
`;

export const CustomerField = styled(Field)`

    @media only screen and (min-width: ${screenSize.tablet}) {
        max-width: 415px;
    }
`;