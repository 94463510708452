import React from 'react';
import {GlobalNav, setGlobalNavUri} from '@snsw/react-global-nav';

setGlobalNavUri(
    `${process.env.REACT_APP_GLOBALNAV_DOMAIN}`,
    { 'x-api-key': `${process.env.REACT_APP_GLOBALNAV_APIKEY}` },
);
const GlobalNavWrapper = (props) => {
    const Component = props.component;
    return (
        <GlobalNav style={ {marginTop: '112px'} }>
            <Component/>
        </GlobalNav>);
};

export default GlobalNavWrapper;
