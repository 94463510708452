import styled from 'styled-components';

export const Container = styled.div`
    background: #fff;
    border-top: 1px solid #eaedf4;
    box-shadow: 0 4px 2px -2px #cfd4d6;
    transition: none;
    z-index: 190;
    height: 70px;

`;

export const Nav = styled.div`
    text-align: center;
    height: 68px;
    display: flex;
    justify-content: flex-start;
    max-width: 1224px;
    margin: 0 auto;
    width: 100%;
    padding-right: 0;
    padding-left: 30px;
    
`;

export const UnselectedMenuLink = styled.a`
    align-items: center;
    display: flex;
    justify-content: center;
    color: #242934;
    height: 70px;
    line-height: 70px;
    text-decoration: none;
    position: relative;
    font-weight: normal;
    margin-right: 2.06rem
`;
export const SelectedMenuLink = styled.a`
    align-items: center;
    display: flex;
    justify-content: center;
    color: #242934;
    height: 70px;
    line-height: 70px;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    margin-right: 2.06rem;
    border-bottom: 3px solid #c60c30
`;
export const NavIcon = styled.svg`
    margin-right: .4rem;
    height: 23px;
    width: 25px
`;